// @ts-nocheck
import React, { useEffect, useState } from 'react';
import image1 from "../assets/images/slide-icon1.png";
import image2 from "../assets/images/slide-icon2.png";
import image3 from "../assets/images/slide-icon3.png";
import image4 from "../assets/images/slide-icon4.png";
import image5 from "../assets/images/slide-icon5.png";


const arrCircle = [
    {
        id: 1, deg: 0, icon: image1,
        title: "Banking and capital markets",
        disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        id: 2, deg: 72, icon: image2,
        title: "Everything connected",
        disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        id: 3, deg: 144, icon: image3,
        title: "UI UX Desginer",
        disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        id: 4, deg: 216, icon: image4,
        title: "Everything connected",
        disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        id: 5, deg: 288, icon: image5,
        title: "Banking and capital markets",
        disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
]

const CircleSlider = () => {
    const [circleArr, setCircleArr] = useState(arrCircle)
    const [rotationDegree, setRotationDegree] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [progressBar, setProgressBar] = useState([1, 2, 3, 4, 5]);


    const handleCircleClick = (item, index) => {

        setRotationDegree(-item?.deg);
        setActiveIndex(index);
    };
    useEffect(() => {
        
        let count = 1
    }, [activeIndex])

    const handleProgressBarClick = (index) => {
        setRotationDegree(-circleArr[index]?.deg);
        setActiveIndex(index);
    };

    useEffect(() => {
        let count = 0
        const animateCircle = () => {
            setRotationDegree((prevDegree) => (prevDegree + 72) % 360);
            if (count == 4) {
                setActiveIndex(0);
                count = 0
            } else {
                setActiveIndex(prevIndex => prevIndex + 1);
                count++
            }
        };

        const intervalId = setInterval(animateCircle, 4000);

        return () => clearInterval(intervalId);
    }, []);


    return (
        <section className="services">
            <div className="circle--slider">
                <div className="rotate--circle count5" style={{ transform: `rotate(${rotationDegree}deg)` }}>
                    <ul className="circle--rotate">
                        {circleArr.map((item, index) => (
                            <li className={`block ${index === activeIndex ? 'active' : ''}`} key={index}>
                                <div className="icon" onClick={() => handleCircleClick(item, index)}>
                                    <img src={item?.icon}  alt='icon' />

                                </div>
                            </li>
                        ))}
                    </ul>
                    {/* Add the rest of your JSX for the animated images and navigation buttons here */}
                </div>
                <div className="industrie_content">
                    {circleArr.map((item, index) => (
                        <div className={`ic__item ${index === activeIndex ? 'active' : ''}`} key={index}>
                            <h4>{item?.title}</h4>
                            <p>{item?.disc}</p>
                        </div>
                    ))}
                    <div class="progressBarContainer">
                        {progressBar.map((item, index) => (
                            <div
                                key={index}
                                className={`bar-item ${index === activeIndex ? 'active' : ''}`}
                                onClick={() => handleProgressBarClick(index)}
                            >
                                <span className="progressBar"></span>
                            </div>
                        ))}
                    </div>
                </div>
                {/* ./industrie_content */}
            </div>
        </section>
    );
};

export default CircleSlider;
